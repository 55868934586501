import { httpDelete, httpGet, httpPost, httpPut } from "shared/api/httpClient";
import { EditableField, getEditableParam } from "shared/types/ApiTypes";
import {
    FullScu,
    SashResponse,
    ScuModelEnum,
    ScuNewTranslationTestResult,
    ScuSearchResult,
    ScuTranslationTestResult,
} from "shared/types/bryx911/Scu";
import {
    ScuAlertSetting,
    ScuAlertSettingParams,
    ScuClearToSend,
    ScuConfig,
    ScuConfigAuditLogReturn,
    ScuJobAlert,
    ScuPrinterConfig,
    ScuZone,
} from "shared/types/bryx911/ScuConfig";
import { ScuAudioResetBehavior, ScuAwsRegion, ScuRampLength, ScuTranslationType, ScuTtsVoice } from "shared/types/bryx911/ScuConfigEnums";
import { ScuEvent } from "shared/types/bryx911/ScuEvents";
import { ScuPeripherals } from "shared/types/bryx911/ScuPeripherals";
import { ScuRule } from "shared/types/bryx911/ScuRules";
import { valueToEditable } from "shared/utils/diff";

export const getScus = (query: string) => {
    // ShortClient[]
    return httpGet<ScuSearchResult[]>(`/scus?query=${query}`);
};

export const getScu = (id: string) => {
    return httpGet<FullScu>(`/scus/${id}`);
};

export const addUnitToScu = (scuId: string, unitId: string) => {
    return httpPost<{ unitId: string }, null>(`/scus/${scuId}/units`, { unitId });
};

export const removeUnitFromScu = (scuId: string, unitId: string) => {
    return httpDelete<null>(`/scus/${scuId}/units/${unitId}`);
};

export const addAgencyToScu = (scuId: string, agencyId: string, canManage: boolean, addToAllUnits: boolean) => {
    return httpPost<{ agencyId: string; canManage: boolean; addToAllUnits: boolean }, null>(`/scus/${scuId}/agencies`, {
        agencyId,
        canManage,
        addToAllUnits,
    });
};

export const removeAgencyFromScu = (scuId: string, agencyId: string) => {
    return httpDelete<null>(`/scus/${scuId}/agencies/${agencyId}`);
};

export const linkStationToScu = (scuId: string, stationId: string) => {
    return httpPut(`/scus/${scuId}`, { stationId });
};

export const exportScuConfig = (scuId: string) => {
    return httpGet<object>(`/scus/${scuId}/server-config`).then((obj) => JSON.stringify(obj));
};

export const importScuConfig = (scuId: string, configString: string) => {
    return httpPut(`/scus/${scuId}/server-config`, configString);
};

export const editScu = (
    scuId: string,
    name: EditableField<string>,
    serial: EditableField<string> | null,
    ipAddress: EditableField<string | null>,
    macAddress: EditableField<string | null>,
    model: EditableField<ScuModelEnum> | null,
    email: EditableField<string | null>,
) => {
    const body = {
        name: getEditableParam(name),
        serial: serial ? getEditableParam(serial) : null,
        ipAddress: getEditableParam(ipAddress),
        macAddress: getEditableParam(macAddress),
        model: model ? getEditableParam(model) : null,
        email: getEditableParam(email),
    };
    return httpPut(`/scus/${scuId}`, body);
};

export const setScuPassword = (scuId: string, password: string) => {
    return httpPut(`/scus/${scuId}`, { password });
};

export const resetScu = (scuId: string) => {
    return httpPost(`/scus/${scuId}`, null);
};

export const getScuConfig = (scuId: string) => {
    return httpGet<ScuConfig>(`/scus/${scuId}/config`);
};
export const runTestTranslation = (scuId: string, raw: string, type: ScuTranslationType) => {
    return httpGet<ScuTranslationTestResult>(`/scus/${scuId}/translate?raw=${raw}&type=${type}`);
};

export const runNewTestTranslation = (scuId: string, raw: string, key: string, logging?: string) => {
    return httpGet<ScuNewTranslationTestResult>(
        `/scus/${scuId}/test-translate?raw=${encodeURIComponent(raw)}&key=${encodeURIComponent(key)}${logging !== undefined ? `&logging=${encodeURIComponent(logging)}` : ""}`,
    );
};

export const pairStationBoard = (scuId: string, zoneId: string, pairingCode: string) => {
    return httpPut(`/scus/${scuId}/config/zones/${zoneId}/connect`, { code: pairingCode });
};

export const editScuTtsSettings = (
    scuId: string,
    voice: ScuTtsVoice | null,
    rate: number | null,
    ttsVolume: number | null,
    repetitions: number | null,
    waitForSpeechSynthesization: boolean | null,
    textToSpeech: string | null,
) => {
    return httpPut(`/scus/${scuId}/config/`, { voice, rate, ttsVolume, repetitions, waitForSpeechSynthesization, textToSpeech });
};

export const editScuRule = (scuId: string, ruleId: string, rule: Omit<ScuRule, "timeout"> & { timeout: EditableField<number | null> }) => {
    return httpPut(`/scus/${scuId}/config/rules/${ruleId}`, rule);
};

export const createScuRule = (scuId: string, rule: ScuRule) => {
    return httpPost(`/scus/${scuId}/config/rules`, rule);
};

export const deleteScuRule = (scuId: string, ruleId: string) => {
    return httpDelete(`/scus/${scuId}/config/rules/${ruleId}`);
};

export const createScuAlertSetting = (scuId: string, alertSetting: ScuAlertSetting) => {
    return httpPost(`/scus/${scuId}/config/alert-settings`, {
        matchType: alertSetting.match.type,
        matchTypeCode: alertSetting.match.typeCode,
        matchUnit: alertSetting.match.unit,
        matchBox: alertSetting.match.box,
        matchPriority: alertSetting.match.priority,
        matchTimeStart: alertSetting.match.timeStart,
        matchTimeEnd: alertSetting.match.timeEnd,
        priority: alertSetting.priority,
        audio: alertSetting.audio,
        color: alertSetting.color,
        lightTimeout: alertSetting.lightTimeout,
        repetitions: alertSetting.repetitions,
        ttsVolume: alertSetting.ttsVolume,
        toneVolume: alertSetting.toneVolume,
        lightRamp: alertSetting.lightRamp,
        brightness: alertSetting.brightness,
        tts: alertSetting.tts,
    });
};

export const editScuAlertSetting = (scuId: string, alertSettingId: string, alertSetting: ScuAlertSettingParams) => {
    return httpPut(`/scus/${scuId}/config/alert-settings/${alertSettingId}`, alertSetting);
};

export const deleteScuAlertSetting = (scuId: string, alertSettingId: string) => {
    return httpDelete(`/scus/${scuId}/config/alert-settings/${alertSettingId}`);
};

export const createScuPrinter = (scuId: string, printer: ScuPrinterConfig) => {
    return httpPost(`/scus/${scuId}/config/printers/`, printer);
};

export const editScuPrinter = (scuId: string, printer: ScuPrinterConfig) => {
    return httpPut(`/scus/${scuId}/config/printers/${printer.id}`, printer);
};

export const deleteScuPrinter = (scuId: string, printerId: string) => {
    return httpDelete(`/scus/${scuId}/config/printers/${printerId}`);
};

export const editScuAudioSettings = (
    scuId: string,
    systemVolume: number | null,
    toneVolume: number | null,
    dayRamp: ScuRampLength | null,
    nightRamp: ScuRampLength | null,
    resetBehavior: EditableField<ScuAudioResetBehavior | null> | null,
) => {
    return httpPut(`/scus/${scuId}/config`, { systemVolume, toneVolume, dayRamp, nightRamp, resetBehavior });
};

export const editScuClearToSendSettings = (scuId: string, clearToSend: ScuClearToSend | null) => {
    return httpPut(`/scus/${scuId}/config`, { clearToSend });
};

export const editScuDaySettings = (scuId: string, dayStart: number | null, dayEnd: number | null) => {
    return httpPut(`/scus/${scuId}/config`, { dayStart, dayEnd });
};

export const editScuGeneralSettings = (
    scuId: string,
    lightTimeout: number | null,
    pollingInterval: number | null,
    dayTurnoutTime: number | null,
    nightTurnoutTime: number | null,
    awsRegion: ScuAwsRegion | null,
    alertNewAssignments: boolean | null,
    alertLateAssignments: boolean | null,
    alertClosedJobs: boolean | null,
    autoRetryLights: boolean | null,
    speakNewAssignments: boolean | null,
    speakLateAssignments: boolean | null,
    speakNewAssignmentsTts: EditableField<string | null> | null,
    speakLateAssignmentsTts: EditableField<string | null> | null,
    lightRamp: number | null,
    sendNetworkReminders: boolean | null,
    unitWaitTime: number | null,
    toneDetectorPrecision: number | null,
    toneDetectorMinDb: number | null,
) => {
    return httpPut(`/scus/${scuId}/config`, {
        lightTimeout,
        pollingInterval,
        dayTurnoutTime,
        nightTurnoutTime,
        awsRegion,
        alertNewAssignments,
        alertLateAssignments,
        alertClosedJobs,
        autoRetryLights,
        speakNewAssignments,
        speakLateAssignments,
        speakNewAssignmentsTts,
        speakLateAssignmentsTts,
        lightRamp,
        sendNetworkReminders,
        unitWaitTime,
        toneDetectorPrecision,
        toneDetectorMinDb,
    });
};

export const editScuInstantAlertSettings = (scuId: string, alert: ScuJobAlert | null) => {
    return httpPut(`/scus/${scuId}/config`, { alert });
};

export const createScuZone = (scuId: string, zone: Omit<ScuZone, "id"> & { name: string }) => {
    return httpPost(`/scus/${scuId}/config/zones`, zone);
};

export const createScuZoneAlertSetting = (scuId: string, zoneId: string, alertSetting: ScuAlertSetting) => {
    return httpPost(`/scus/${scuId}/config/zones/${zoneId}/alert-settings`, {
        matchType: alertSetting.match.type,
        matchTypeCode: alertSetting.match.typeCode,
        matchUnit: alertSetting.match.unit,
        matchBox: alertSetting.match.box,
        matchPriority: alertSetting.match.priority,
        matchTimeStart: alertSetting.match.timeStart,
        matchTimeEnd: alertSetting.match.timeEnd,
        priority: alertSetting.priority,
        audio: alertSetting.audio,
        color: alertSetting.color,
        lightTimeout: alertSetting.lightTimeout,
        repetitions: alertSetting.repetitions,
        ttsVolume: alertSetting.ttsVolume,
        toneVolume: alertSetting.toneVolume,
        lightRamp: alertSetting.lightRamp,
        brightness: alertSetting.brightness,
        tts: alertSetting.tts,
    });
};

export const editScuZoneAlertSetting = (scuId: string, zoneId: string, alertSettingId: string, alertSetting: ScuAlertSetting) => {
    return httpPut(`/scus/${scuId}/config/zones/${zoneId}/alert-settings/${alertSettingId}`, {
        matchType: valueToEditable(alertSetting.match.type),
        matchTypeCode: valueToEditable(alertSetting.match.typeCode),
        matchUnit: valueToEditable(alertSetting.match.unit),
        matchBox: valueToEditable(alertSetting.match.box),
        matchPriority: valueToEditable(alertSetting.match.priority),
        matchTimeStart: valueToEditable(alertSetting.match.timeStart),
        matchTimeEnd: valueToEditable(alertSetting.match.timeEnd),
        priority: alertSetting.priority,
        audio: valueToEditable(alertSetting.audio),
        color: valueToEditable(alertSetting.color),
        lightTimeout: valueToEditable(alertSetting.lightTimeout),
        repetitions: valueToEditable(alertSetting.repetitions),
        ttsVolume: valueToEditable(alertSetting.ttsVolume),
        toneVolume: valueToEditable(alertSetting.toneVolume),
        lightRamp: valueToEditable(alertSetting.lightRamp),
        brightness: valueToEditable(alertSetting.brightness),
        tts: valueToEditable(alertSetting.tts),
    });
};

export const deleteScuZoneAlertSetting = (scuId: string, zoneId: string, alertSettingId: string) => {
    return httpDelete(`/scus/${scuId}/config/zones/${zoneId}/alert-settings/${alertSettingId}`);
};

export const editScuZone = (scuId: string, zone: ScuZone) => {
    return httpPut(`/scus/${scuId}/config/zones/${zone.id}`, zone);
};

export const deleteScuZone = (scuId: string, zoneId: string) => {
    return httpDelete(`/scus/${scuId}/config/zones/${zoneId}`);
};

export const sendSashCommand = (scuId: string, command: string, wait: boolean, args: object | null): Promise<SashResponse> => {
    return httpPost(`/scus/${scuId}/sash`, { command, wait, args });
};

export const getSashResponse = (sashId: string) => {
    return httpGet<SashResponse>(`/scus/sash/${sashId}`);
};

export const getScuEvents = (scuId: string) => {
    return httpGet<ScuEvent[]>(`/scus/${scuId}/events`);
};

export const getScuPeripherals = (scuId: string) => {
    return httpGet<ScuPeripherals>(`/scus/${scuId}/peripherals`);
};

export const updateScuPeripherals = (scuId: string, peripherals: ScuPeripherals) => {
    return httpPut(`/scus/${scuId}/peripherals`, peripherals);
};

export const getScuAuditLog = (scuId: string) => {
    return httpGet<ScuConfigAuditLogReturn>(`/scus/${scuId}/audit`);
};

export const getTestTranslate = (scuId: string, query: string): Promise<string> => {
    return httpGet(`/scus/${scuId}/test-translate?${query}`);
};
